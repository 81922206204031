<template>
  <div class="history">
    <!-- 1 -->
    <div class="title">История</div>
    <!-- 2 -->
    <div class="bgimg"></div>
    <!-- 3 -->
    <div class="subtitle">
      {{ history }}
    </div>
    <!-- 4 -->
    <div class="stat">
      <div class="title0">{{ title0 }}</div>
      <div class="num0">&nbsp;<br />{{ num0 }}<br />&nbsp;</div>
      <div class="title1">{{ title1 }}</div>
      <div class="num1">&nbsp;<br />{{ num1 }}<br />&nbsp;</div>
      <div class="title2">{{ title2 }}</div>
      <div class="num2">&nbsp;<br />{{ num2 }}<br />&nbsp;</div>
      <div class="title3">{{ title3 }}</div>
      <div class="num3">&nbsp;<br />{{ num3 }}<br />&nbsp;</div>
      <div class="title4">{{ title4 }}</div>
      <div class="num4">&nbsp;<br />{{ num4 }}<br />&nbsp;</div>
      <div class="title5">{{ title5 }}</div>
      <div class="num5">&nbsp;<br />{{ num5 }}<br />&nbsp;</div>
    </div>
    <!-- 5 -->
    <div class="subtitle2">
      {{ subtitle2 }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      history: "",
      title0: "",
      num0: "",
      title1: "",
      num1: "",
      title2: "",
      num2: "",
      title3: "",
      num3: "",
      title4: "",
      num4: "",
      title5: "",
      num5: "",
      subtitle2: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await fetch("https://cd20321.tmweb.ru/api/articles/");
      const finalRes = await res.json();
      this.history = finalRes[12].text;
      this.num0 = finalRes[13].text;
      this.title0 = finalRes[14].text;
      this.num1 = finalRes[16].text;
      this.title1 = finalRes[15].text;
      this.num2 = finalRes[17].text;
      this.title2 = finalRes[18].text;
      this.num3 = finalRes[20].text;
      this.title3 = finalRes[19].text;
      this.num4 = finalRes[21].text;
      this.title4 = finalRes[22].text;
      this.num5 = finalRes[24].text;
      this.title5 = finalRes[23].text;
      this.subtitle2 = finalRes[25].text;
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 416px) {
  .history {
    .title {
      font-weight: 600;
      font-size: 100px;
      line-height: 144px;
      text-align: center;
    }
    .bgimg {
      background-image: url("../assets/history/bg.png");
      background-size: cover;
      height: 538px;
    }
    .subtitle {
      display: grid;
      grid-template-columns: minmax(auto, 666px);
      justify-content: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      text-align: center;
      margin: 57px 0;
    }
    .stat {
      display: grid;
      grid-template-columns: 1fr minmax(auto, 202px) 1fr;
      grid-template-rows: repeat(6, 180px);
      align-items: center;
      background-color: white;
      .title0 {
        grid-column: 3;
        grid-row: 1;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        margin-left: 50px;
      }
      .num0 {
        grid-column: 2;
        grid-row: 1;
        background-color: #d9e1cd;
        font-weight: bold;
        font-size: 60px;
        line-height: 86%;
        border-radius: 5px 5px 0 0;
        text-align: right;
      }
      .title1 {
        grid-column: 1;
        grid-row: 2;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        justify-self: end;
        margin-right: 50px;
      }
      .num1 {
        grid-column: 2;
        grid-row: 2;
        background-color: #d9e1cd;
        font-weight: bold;
        font-size: 60px;
        line-height: 86%;
        border-radius: 5px 5px 0 0;
      }
      .title2 {
        grid-column: 3;
        grid-row: 3;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        margin-left: 50px;
      }
      .num2 {
        grid-column: 2;
        grid-row: 3;
        background-color: #d9e1cd;
        font-weight: bold;
        font-size: 60px;
        line-height: 86%;
        text-align: right;
      }
      .title3 {
        grid-column: 1;
        grid-row: 4;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        justify-self: end;
        text-align: right;
        margin-right: 50px;
      }
      .num3 {
        grid-column: 2;
        grid-row: 4;
        background-color: #d9e1cd;
        font-weight: bold;
        font-size: 60px;
        line-height: 86%;
      }
      .title4 {
        grid-column: 3;
        grid-row: 5;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        margin-left: 50px;
      }
      .num4 {
        grid-column: 2;
        grid-row: 5;
        background-color: #d9e1cd;
        font-weight: bold;
        font-size: 60px;
        line-height: 86%;
        text-align: right;
      }
      .title5 {
        grid-column: 1;
        grid-row: 6;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        justify-self: end;
        text-align: right;
        margin-right: 50px;
      }
      .num5 {
        grid-column: 2;
        grid-row: 6;
        background-color: #d9e1cd;
        font-weight: bold;
        font-size: 60px;
        line-height: 86%;
        border-radius: 0 0 5px 5px;
      }
    }
    .subtitle2 {
      display: grid;
      grid-template-columns: minmax(auto, 717px);
      justify-content: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      text-align: center;
      margin: 57px 0;
    }
  }
}
@media (max-width: 415px) {
  .history {
    .title {
      font-weight: 600;
      font-size: 50px;
      line-height: 72px;
      text-align: center;
      margin-bottom: 30px;
    }
    .bgimg {
      background-image: url("../assets/history/bgm.png");
      background-size: cover;
      height: 516px;
    }
    .subtitle {
      display: grid;
      grid-template-columns: minmax(auto, 335px);
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      margin: 61px 0 30px 0;
    }
    .stat {
      display: grid;
      grid-template-columns: minmax(auto, 335px);
      grid-template-rows: repeat(10, auto);
      align-items: center;
      background-color: #d9e1cd;
      margin: 0 20px;
      border-radius: 5px;
      padding: 0 20px;
      .title0 {
        grid-column: 1;
        grid-row: 2;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        margin-right: 50px;
      }
      .num0 {
        grid-column: 1;
        grid-row: 1;
        background-color: #d9e1cd;
        font-weight: bold;
        font-size: 40px;
        line-height: 86%;
        border-radius: 5px 5px 0 0;
        width: 100px;
      }
      .title1 {
        grid-column: 1;
        grid-row: 4;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        margin-right: 50px;
      }
      .num1 {
        grid-column: 1;
        grid-row: 3;
        background-color: #d9e1cd;
        font-weight: bold;
        font-size: 40px;
        line-height: 86%;
        border-radius: 5px 5px 0 0;
        width: 100px;
      }
      .title2 {
        grid-column: 1;
        grid-row: 6;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
      }
      .num2 {
        grid-column: 1;
        grid-row: 5;
        background-color: #d9e1cd;
        font-weight: bold;
        font-size: 40px;
        line-height: 86%;
        width: 100px;
      }
      .title3 {
        grid-column: 1;
        grid-row: 8;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
      }
      .num3 {
        grid-column: 1;
        grid-row: 7;
        background-color: #d9e1cd;
        font-weight: bold;
        font-size: 40px;
        line-height: 86%;
        width: 100px;
      }
      .title4 {
        grid-column: 1;
        grid-row: 10;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
      }
      .num4 {
        grid-column: 1;
        grid-row: 9;
        background-color: #d9e1cd;
        font-weight: bold;
        font-size: 40px;
        line-height: 86%;
        width: 100px;
      }
      .title5 {
        grid-column: 1;
        grid-row: 12;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 30px;
      }
      .num5 {
        grid-column: 1;
        grid-row: 11;
        background-color: #d9e1cd;
        font-weight: bold;
        font-size: 40px;
        line-height: 86%;
        width: 100px;
      }
    }
    .subtitle2 {
      display: grid;
      grid-template-columns: minmax(auto, 335px);
      justify-content: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      margin: 30px 0;
    }
  }
}
</style>
